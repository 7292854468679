import React from "react";
import styled from "styled-components";

import { graphql, PageProps } from "gatsby";
import { Query } from "@graphql-types";
import { SEO, Header } from "@global";
import ContentHubContent from "@components/contentHub/contentHubContent";
import ContentBlock from "@components/shared/sub/contentBlock";
import { Container } from "@util/standard";
import { colors, TABLET_BREAKPOINT } from "@util/constants";

interface Props extends PageProps {
  data: Query;
}

const BlogTemplate = ({ data }: Props) => {
  if (data.sanityContentHub == null) return null;

  const { seo, pageContent, headerText } = data.sanityContentHub;
  console.log(data.sanityContentHub);

  return (
    <div className="subpage-container">
      <SEO data={seo} />
      <Header>
        <HeaderTextWrapper width="80%" margin="auto" maxWidth="1380px">
          {headerText?._rawContent && <ContentBlock blocks={headerText?._rawContent} />}
        </HeaderTextWrapper>
      </Header>
      {pageContent && <ContentHubContent data={pageContent} />}
    </div>
  );
};

export const query = graphql`
  query contentHubPageQuery($slug: String) {
    sanityContentHub(slug: { current: { eq: $slug } }) {
      seo {
        ...sanitySeo
      }
      pageTitle
      slug {
        current
      }
      headerText {
        _rawContent(resolveReferences: { maxDepth: 5 })
      }
      pageContent {
        ... on SanityVideoSlider {
          ...sanityVideoSlider
        }
        ... on SanityGuideBlock {
          ...sanityGuideBlock
        }
        ... on SanityCalculatorsBlock {
          ...sanityCalculatorsBlock
        }
        ... on SanityBlogsBlock {
          ...sanityBlogsBlock
        }
      }
    }
  }
`;

export default BlogTemplate;

const HeaderTextWrapper = styled(Container)`
  h1 {
    font-size: 40px;
    line-height: 45px;
  }
  p {
    color: ${colors.white};
    width: 65%;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding-top: 50px;
    p {
      width: 100%;
      margin-bottom: 50px;
    }
  }
`;
