import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import { SanityBlogs } from "@graphql-types";
import { CategoryTag, Container } from "@util/standard";

import { colors, colorsRGB } from "@util/constants";
import { Color } from "@util/types";
import { setBlogSlug } from "@util/helpers";
import { Image } from "@global";

interface Props {
  data: SanityBlogs;
}

function BlogRowPreview({ data }: Props) {
  if (data == null) return null;

  const { categories, title, featureImage, publishdate, author, excerpt } = data;

  const firstCategory = categories?.[0];
  const blogSlug = setBlogSlug(data);

  return (
    <Link to={blogSlug} style={{ width: "100%" }}>
      <BlogWrapper>
        <ImageWrapper>{featureImage && <Image data={featureImage} />}</ImageWrapper>
        <TextWrapper>
          <Container width="100%" justifyContent="space-between">
            {firstCategory && (
              <CategoryTag backgroundColor={firstCategory.colorList as Color} className="category">
                {firstCategory.CategoryName}
              </CategoryTag>
            )}
          </Container>
          <h3>{title}</h3>
          <p>{excerpt}</p>

          <p className="author">
            {publishdate} | By {author ?? "Ben Pauley"}
          </p>
        </TextWrapper>
      </BlogWrapper>
    </Link>
  );
}

export default BlogRowPreview;

const BlogWrapper = styled(Container)`
  border: 1px solid ${colorsRGB.maroon(0.2)};
  border-radius: 3px;
`;

const ImageWrapper = styled(Container)`
  width: 47%;

  .gatsby-image-wrapper {
    width: 100%;
  }
`;
const TextWrapper = styled(Container)`
  width: 42%;
  margin: 60px auto;
  flex-direction: column;
  color: ${colors.maroon};

  .author {
    margin-top: 30px;
  }

  .category {
    margin-bottom: 20px;
  }
  h3 {
    color: ${colors.maroon};
    font-size: 34px;
    line-height: 40px;
    margin-bottom: 100px;
  }
`;
