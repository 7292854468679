import React, { useState } from "react";
import styled from "styled-components";

import { colors, TABLET_BREAKPOINT, MOBILE_BREAKPOINT } from "@util/constants";
import { GridContainer, Container } from "@util/standard";
import { SanityCalculatorsBlock } from "@graphql-types";
import { CalculatorPreview } from "@global";
import { useApplyFilterSearch } from "@util/hooks";
import { ContentHubQueries } from "./contentHubContent";

interface Props {
  data: SanityCalculatorsBlock;
  searchState: ContentHubQueries;
}

function CalculatorsBlock({ data, searchState }: Props) {
  if (data == null) return null;
  const [numberToShow, setNumberToShow] = useState(3);

  const { title, calculators } = data;
  const filteredCalculators = useApplyFilterSearch(calculators, searchState);

  if (filteredCalculators == null || filteredCalculators.length === 0) return null;

  return (
    <CalculatorsBlockWrapper>
      <h2 id="calculators">{title}</h2>
      <GridContainer repeat={2} columnGap={40} rowGap={40} tabletRepeat={1} mobileRowGap={15}>
        {filteredCalculators?.map((calculator, index) => {
          if (calculators == null) return null;

          if (index > numberToShow) return null;

          const calculatorData = {
            title: calculator?.title,
            categories: calculator?.categories,
            description: calculator?.description,
            seo: calculator?.seo,
          };

          return <CalculatorPreview data={calculatorData} />;
        })}
      </GridContainer>
      {filteredCalculators && numberToShow + 1 < filteredCalculators.length && (
        <Container width="max-content" margin="60px auto 0 auto">
          <button className="theme-btn block red" onClick={() => setNumberToShow(numberToShow + 4)}>
            Load more
          </button>
        </Container>
      )}
    </CalculatorsBlockWrapper>
  );
}

const CalculatorsBlockWrapper = styled(Container)`
  flex-direction: column;
  max-width: 1380px;
  width: 80%;
  margin: auto;
  margin-bottom: 120px;
  h2 {
    color: ${colors.maroon};
    font-size: 40px;
    margin-bottom: 50px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 90%;
    margin-bottom: 60px;

    h2 {
      color: ${colors.maroon};
      font-size: 28px;
      margin-bottom: 30px;
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
  }
`;

export default CalculatorsBlock;
