import { Query } from "@graphql-types"
import { graphql, useStaticQuery } from "gatsby"

const getAllCategoryQuery = () => {
  const { allSanityCategory }: Query = useStaticQuery(graphql`
    query allSanityCategoryQuery {
      allSanityCategory {
        nodes {
          _id
          _type
          id
          colorList
          CategoryName
        }
      }
    }
  `)
  if (allSanityCategory == null) return null

  return allSanityCategory.nodes
}
export default getAllCategoryQuery
