import React, { useState } from "react";
import styled from "styled-components";

import { Maybe, SanityBlogsBlockOrCalculatorsBlockOrGuideBlockOrVideoSlider } from "@graphql-types";
import { colors, colorsRGB, TABLET_BREAKPOINT } from "@util/constants";
import { Container } from "@util/standard";
import ContentHubTopBar from "./contentHubTopBar";
import { isBlogsBlock, isCalculatorsBlock, isGuideBlock, isVideoSlider } from "@util/types";
import GuidesBlock from "./guidesBlock";
import CalculatorsBlock from "./calculatorsBlock";
import BlogsBlock from "./blogsBlock";
import { VideoSlider } from "@global";

interface Props {
  data: Maybe<Array<Maybe<SanityBlogsBlockOrCalculatorsBlockOrGuideBlockOrVideoSlider>>>;
}

export interface ContentHubQueries {
  searchQuery: string;
  filters: string[];
}

const INITIAL_SEARCH = {
  searchQuery: "",
  filters: [],
};
function ContentHubContent({ data }: Props) {
  if (data == null) return null;
  const [contentSearch, setContentSearch] = useState<ContentHubQueries>(INITIAL_SEARCH);

  return (
    <ContentHubWrapper>
      <StickyWrapper>
        <ContentHubTopBar
          searchState={contentSearch}
          setSearchState={setContentSearch}
          resetSearch={() => setContentSearch(INITIAL_SEARCH)}
        />
      </StickyWrapper>
      <ContentWrapper id="results">
        {data.map(item => {
          if (item == null) return null;

          if (isGuideBlock(item)) {
            return (
              <Section>
                <GuidesBlock data={item} searchState={contentSearch} />
              </Section>
            );
          }
          if (isBlogsBlock(item)) {
            return (
              <Section>
                <BlogsBlock data={item} searchState={contentSearch} />
              </Section>
            );
          }
          if (isCalculatorsBlock(item)) {
            return (
              <Section>
                <CalculatorsBlock data={item} searchState={contentSearch} />
              </Section>
            );
          }

          if (isVideoSlider(item)) {
            return (
              <Section>
                <VideoSlider data={item} searchState={contentSearch} />
              </Section>
            );
          }
          return null;
        })}
      </ContentWrapper>
    </ContentHubWrapper>
  );
}

export default ContentHubContent;

const Section = styled.section``;

const ContentHubWrapper = styled(Container)`
  position: relative;

  flex-direction: column;
`;

const StickyWrapper = styled(Container)`
  position: sticky;
  top: 100px;
  height: 57px;
  z-index: 2;
  width: 100%;
  background: ${colors.darkCream};
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 110px;
    top: 85px;

    background: ${colors.cream};
  }
`;

const ContentWrapper = styled(Container)`
  flex-direction: column;
  margin-top: 120px;
`;
