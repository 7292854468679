import { Query } from "@graphql-types"
import { graphql, useStaticQuery } from "gatsby"

const getAllBlogsQuery = () => {
  const { allSanityBlogs }: Query = useStaticQuery(graphql`
    query allSanityBlogsQuery {
      allSanityBlogs(sort: { fields: publishdate, order: DESC }) {
        nodes {
          ...sanityBlogsPreview
        }
      }
    }
  `)
  if (allSanityBlogs == null) return null

  return allSanityBlogs.nodes
}
export default getAllBlogsQuery
