import React, { Dispatch, ReactNode, SetStateAction, useRef, useState } from "react";
import styled from "styled-components";

import { colors, colorsRGB, fontFamilies, TABLET_BREAKPOINT } from "@util/constants";
import { Container, DesktopContainer, MobileContainer } from "@util/standard";
import { ArrowMaroon, IconClose, SearchIcon } from "@util/assets";
import { CheckBox } from "@global";
import { useAllCategoryQuery } from "@api";
import { Color } from "@util/types";
import { ContentHubQueries } from "./contentHubContent";
import { Maybe } from "@graphql-types";
import { useEventListener, useOnClickOutside } from "@util/hooks";
import { isBrowser } from "@util/helpers";

interface Props {
  searchState: ContentHubQueries;
  setSearchState: Dispatch<SetStateAction<ContentHubQueries>>;
  resetSearch: () => void;
}

interface DropdownProps {
  title: string;
  children: ReactNode;
  alignment: "left" | "right";
}

const DropDownNew = ({ title, children, alignment }: DropdownProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropDownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(dropDownRef, () => setDropdownOpen(false));

  return (
    <Container position="relative" width="100%" ref={dropDownRef}>
      <DropDown selected={dropdownOpen} onClick={() => setDropdownOpen(!dropdownOpen)}>
        <Container justifyContent="space-between" width="100%">
          <span>{title}</span> <ArrowMaroon />
        </Container>
      </DropDown>
      {dropdownOpen && <FilterWrapper alignment={alignment}>{children}</FilterWrapper>}
    </Container>
  );
};

function ContentHubTopBar({ searchState, setSearchState, resetSearch }: Props) {
  const [searchValue, setSearchValue] = useState("");

  const handleClick = (value: string) => {
    if (value == null) return;

    const section = document.getElementById(value);

    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const handleChangeSearch = (val: string) => {
    setSearchValue(val);
    if (!val) resetSearch();
  };

  const handleChange = (id: Maybe<string> | undefined) => {
    if (id == null) return;

    if (searchState.filters?.includes(id)) {
      const newFilters = searchState.filters.filter(item => item !== id);
      setSearchState({ ...searchState, filters: newFilters });
      return;
    }

    setSearchState({ ...searchState, filters: [...searchState.filters, id] });
  };

  const handleSearch = () => {
    setSearchState({ ...searchState, searchQuery: searchValue });
    const topOfResults = document.getElementById("results");
    isBrowser() &&
      topOfResults &&
      topOfResults.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  useEventListener("keyup", (e: KeyboardEvent) => {
    if (e && e.code === "Enter" && searchValue !== "") {
      handleSearch();
    }
  });

  const allCategories = useAllCategoryQuery();

  return (
    <Container
      width="80%"
      tabletWidth="91%"
      maxWidth="1380px"
      margin="auto"
      justifyContent="space-between"
      tabletFlexDirection="column"
    >
      <Container>
        <DesktopContainer>
          <ContentTab first onClick={() => handleClick("guides")}>
            Guides
          </ContentTab>
          <ContentTab onClick={() => handleClick("calculators")}>Calculators</ContentTab>
          <ContentTab onClick={() => handleClick("videos")}>Videos</ContentTab>
          <ContentTab onClick={() => handleClick("blogsArticles")}>Blog Articles</ContentTab>
        </DesktopContainer>
        <MobileContainer justifyContent="space-between" width="100%">
          <Container width="65%">
            <DropDownNew title="Go to" alignment="left">
              <ContentTab onClick={() => handleClick("guides")}>Guides</ContentTab>
              <ContentTab onClick={() => handleClick("calculators")}>Calculators</ContentTab>
              <ContentTab onClick={() => handleClick("videos")}>Videos</ContentTab>
              <ContentTab onClick={() => handleClick("blogsArticles")}>Blog Articles</ContentTab>
            </DropDownNew>
          </Container>
          <Container width="30%">
            {allCategories && (
              <DropDownNew title="Filter" alignment="right">
                {allCategories.map(category => {
                  if (category?._id == null) return null;

                  const isChecked = searchState.filters.includes(category?._id);

                  return (
                    <CheckBox
                      label={category.CategoryName}
                      color={category.colorList as Color}
                      onChange={() => handleChange(category._id)}
                      checkOverride={isChecked}
                    />
                  );
                })}
              </DropDownNew>
            )}
          </Container>
        </MobileContainer>
      </Container>
      <Container width="32%" tabletWidth="100%" tabletMargin="10px 0 0 0">
        <SearchWrapper>
          <SearchIcon onClick={handleSearch} />
          <SearchBar
            placeholder="Search"
            value={searchValue}
            onChange={e => handleChangeSearch(e.target.value)}
          />
          <IconClose width={16} height={16} onClick={() => handleChangeSearch("")} />
        </SearchWrapper>
        <DesktopContainer position="relative">
          {allCategories && (
            <DropDownNew title="Filter" alignment="right">
              {allCategories.map(category => {
                if (category?._id == null) return null;

                const isChecked = searchState.filters.includes(category._id);

                return (
                  <CheckBox
                    label={category.CategoryName}
                    color={category.colorList as Color}
                    onChange={() => handleChange(category._id)}
                    checkOverride={isChecked}
                  />
                );
              })}
            </DropDownNew>
          )}
        </DesktopContainer>
      </Container>
    </Container>
  );
}

export default ContentHubTopBar;

const ContentTab = styled.button<{ selected?: boolean; first?: boolean }>`
  background: transparent;
  border: none;
  width: 150px;

  color: ${colors.maroon};
  padding: 17px 0;
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid ${colors.maroon};
    padding: 14px 0 13px 0;
    font-family: ${fontFamilies.bold};
  }
  ${({ first }) => first && `padding-left: 0;`}
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    text-align: left;
    &:hover {
      border-bottom: none;
      padding: 10px 0 10px 0;
      font-family: ${fontFamilies.bold};
    }
  }
`;

const SearchWrapper = styled(Container)`
  background: ${colors.white};
  height: 41px;
  margin: auto 15px auto 0;
  padding: 0 20px;
  width: 100%;
  svg {
    cursor: pointer;
    margin: auto 0;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 0;
  }
`;

const SearchBar = styled.input`
  border: none;
  width: 82%;
  margin-left: 10px;

  &:focus-visible {
    outline: none;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
  }
`;

const DropDown = styled.button<{ selected?: boolean }>`
  background: transparent;
  border: 1px solid ${colors.maroon};
  height: 41px;
  width: 100%;
  margin: auto;
  padding: 0 20px;
  border-radius: 3px;

  cursor: pointer;
  svg {
    margin: auto 0 auto 30px;
    ${({ selected }) => (selected ? `transform: rotate(0deg);` : `transform: rotate(180deg);`)};
    transition: transform 0.2s;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    svg {
      margin: auto 0;
    }
  }
`;

const FilterWrapper = styled(Container)<{ alignment?: string }>`
  position: absolute;
  top: calc(100% + 30px);
  z-index: 2;
  background: ${colors.cream};
  ${({ alignment }) => (alignment && alignment === "right" ? "right: 0px;" : "left: 0px;")};
  flex-direction: column;
  border: 1px solid ${colorsRGB.maroon(0.2)};
  padding: 20px;
  border-radius: 3px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    top: calc(100% + 10px);
  }
`;
