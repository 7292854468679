import React, { useState } from "react"
import styled from "styled-components"

import { SanityGuideBlock } from "@graphql-types"
import { Container, GridContainer } from "@util/standard"
import { GuidePreview } from "@global"
import { colors, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants"
import { ContentHubQueries } from "./contentHubContent"
import { useApplyFilterSearch } from "@util/hooks"

interface Props {
  data: SanityGuideBlock
  searchState: ContentHubQueries
}

function GuidesBlock({ data, searchState }: Props) {
  if (data == null) return null
  const [numberToShow, setNumberToShow] = useState(3)

  const { title, guides } = data

  const filterData = useApplyFilterSearch(guides, searchState)

  if (filterData == null || filterData.length === 0) return null

  return (
    <GuideBlockWrapper>
      <h2 id="guides">{title}</h2>
      <GridContainer
        repeat={2}
        columnGap={40}
        rowGap={40}
        tabletRepeat={1}
        mobileRowGap={15}
      >
        {filterData?.map((guide, index) => {
          if (guide == null) return null

          if (index > numberToShow) return null

          return <GuidePreview data={guide} />
        })}
      </GridContainer>
      {filterData && numberToShow + 1 < filterData.length && (
        <Container width="max-content" margin="60px auto 0 auto">
          <button
            className="theme-btn block red"
            onClick={() => setNumberToShow(numberToShow + 4)}
          >
            Load more
          </button>
        </Container>
      )}
    </GuideBlockWrapper>
  )
}

export default GuidesBlock

const GuideBlockWrapper = styled(Container)`
  flex-direction: column;
  max-width: 1380px;
  width: 80%;
  margin: auto;
  margin-bottom: 120px;

  h2 {
    color: ${colors.maroon};
    font-size: 40px;
    margin-bottom: 50px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 90%;
    margin-bottom: 60px;

    h2 {
      color: ${colors.maroon};
      font-size: 28px;
      margin-bottom: 30px;
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
  }
`
