import { useAllBlogsQuery } from "@api";

import { Maybe, SanityBlogs, SanityBlogsBlock, SanityFlightImage } from "@graphql-types";
import { colors, TABLET_BREAKPOINT, colorsRGB, fontFamilies } from "@util/constants";
import { CategoryTag, Container, DesktopContainer, MobileContainer } from "@util/standard";
import React, { useState } from "react";
import styled from "styled-components";
import BlogBlock from "../blogs/blogBlock";
import BlogRowPreview from "./blogRowPreview";
import { Color } from "@util/types";
import { Link } from "gatsby";
import { ContentHubQueries } from "./contentHubContent";
import { useApplyFilterSearch } from "@util/hooks";
import { setBlogSlug } from "@util/helpers";
import { Image } from "@global";

interface Props {
  data: SanityBlogsBlock;
  searchState: ContentHubQueries;
}

interface SideBarProps {
  data: {
    title: Maybe<string> | undefined;
    image: Maybe<SanityFlightImage> | undefined;
    blogs: Maybe<Maybe<SanityBlogs>[]> | undefined;
  };
}

function BlogsBlock({ data, searchState }: Props) {
  if (data == null) return null;
  const [numberToShow, setNumberToShow] = useState(10);

  const { title, sideBarBlogs, sideBarImage, SidebarTitle } = data;

  const allBlogs = useAllBlogsQuery();

  const filteredBlogs = useApplyFilterSearch(allBlogs, searchState);

  const sideBarData = {
    title: SidebarTitle,
    image: sideBarImage,
    blogs: sideBarBlogs,
  };

  return (
    <ArticlesBlockWrapper>
      <h2 id="blogsArticles">{title}</h2>
      <ColumnsWrapper>
        <BlogsWrapper>
          {filteredBlogs == null || (filteredBlogs.length === 0 && <h2>No blogs found</h2>)}
          {filteredBlogs &&
            filteredBlogs.map((blog, index) => {
              if (blog == null) return null;

              if (index + 1 > numberToShow) return null;
              const isRowVariant = index === 0 || index % 5 === 0;

              if (isRowVariant) {
                return (
                  <>
                    <Container width={isRowVariant ? "100%" : "47.5%"}>
                      <DesktopContainer width="100%">
                        <BlogRowPreview data={blog} />
                      </DesktopContainer>
                      <MobileContainer width="100%">
                        <BlogBlock blog={blog} />
                        {index === 3 && <SideBar data={sideBarData} />}
                      </MobileContainer>
                    </Container>
                  </>
                );
              }

              return (
                <Container
                  width={isRowVariant ? "100%" : "47.5%"}
                  tabletWidth="100%"
                  display="block"
                  height="auto"
                  flexDirection="column"
                >
                  <BlogBlock blog={blog} />
                  {index === 3 && (
                    <MobileContainer margin="20px 0 0 0">
                      <SideBar data={sideBarData} />
                    </MobileContainer>
                  )}
                </Container>
              );
            })}
        </BlogsWrapper>
        <DesktopContainer width="31%">
          <SideBar data={sideBarData} />
        </DesktopContainer>
      </ColumnsWrapper>
      {filteredBlogs && numberToShow + 1 < filteredBlogs.length && (
        <Container width="65%" tabletWidth="100%">
          <Container width="max-content" margin="60px auto 0 auto">
            <button
              className="theme-btn block red"
              onClick={() => setNumberToShow(numberToShow + 4)}
            >
              Load more
            </button>
          </Container>
        </Container>
      )}
    </ArticlesBlockWrapper>
  );
}

const SideBar = ({ data }: SideBarProps) => {
  if (data == null) return null;

  const { title, image, blogs } = data;

  return (
    <SideBarWrapper>
      <Container>
        {image && (
          <SideBarImageWrapper>
            <Image data={image} />
          </SideBarImageWrapper>
        )}
        <h3>{title}</h3>
      </Container>
      {blogs && (
        <Container flexDirection="column" margin="10% 0 0 0">
          {blogs.map((blog, index) => {
            if (blog == null) return null;

            const { title, categories, author, publishdate } = blog;
            const firstCategory = categories?.[0];
            const categoryColor = firstCategory && firstCategory.colorList;
            const isLast = index + 1 === blogs.length;
            const blogSlug = setBlogSlug(blog);

            return (
              <SideBarBlog isLast={isLast}>
                {firstCategory && categoryColor && (
                  <CategoryTag backgroundColor={firstCategory.colorList as Color}>
                    {firstCategory.CategoryName}
                  </CategoryTag>
                )}
                <Link to={blogSlug}>
                  <h4>{title}</h4>
                </Link>
                <p className="author">
                  {publishdate} | By {author ?? "Ben Pauley"}
                </p>
              </SideBarBlog>
            );
          })}
        </Container>
      )}
    </SideBarWrapper>
  );
};

const ArticlesBlockWrapper = styled(Container)`
  flex-direction: column;
  max-width: 1380px;
  width: 80%;
  margin: auto;
  margin-bottom: 120px;
  h2 {
    color: ${colors.maroon};
    font-size: 40px;
    margin-bottom: 50px;
  }

  .image-link {
    width: 100%;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 90%;
    margin-bottom: 60px;

    h2 {
      color: ${colors.maroon};
      font-size: 28px;
      margin-bottom: 30px;
    }
  }
`;

const ColumnsWrapper = styled(Container)`
  justify-content: space-between;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const BlogsWrapper = styled(Container)`
  width: 65%;
  flex-wrap: wrap;
  column-gap: 5%;
  row-gap: 40px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    row-gap: 20px;
  }
`;

const SideBarWrapper = styled(Container)`
  position: sticky;
  top: 133px;
  max-height: 77vh;
  width: 100%;
  height: max-content;
  flex-direction: column;
  background: ${colorsRGB.maroon(0.05)};
  color: ${colors.maroon};
  padding: 10%;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: lightgrey;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${colors.maroon};
    border-radius: 50px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${colors.maroon};
  }

  h3 {
    font-size: 24px;
    color: ${colors.maroon};
    margin: auto 0;
  }
  h4 {
    color: ${colors.maroon};
    font-size: 18px;
    line-height: 21px;
    margin: 5% 0;
    font-family: ${fontFamilies.medium};
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    max-height: 100%;
  }
`;

const SideBarImageWrapper = styled(Container)`
  .gatsby-image-wrapper {
    width: 65px;
    height: 65px;
    border-radius: 50%;
  }
  margin-right: 15px;
`;

const SideBarBlog = styled(Container)<{ isLast?: boolean }>`
  flex-direction: column;
  border-bottom: 1px solid ${colorsRGB.maroon(0.2)};
  padding-bottom: 20px;
  margin-bottom: 20px;
  ${({ isLast }) => isLast && `border: none; margin-bottom: 0; padding-bottom: 0;`}
`;

export default BlogsBlock;
